<template>
  <b-card-code title="Créer un nouveau responsable">
    <b-card-text>
      <span>Veuillez saisir les coordonnées de responsable  </span>
    </b-card-text>

    <!-- form -->
    <validation-observer ref="simpleRules">
      <b-form>
        <b-row>
          <b-col md="6">
            <b-form-group
              label="Manager"
              label-for="Manager"
            >
              <validation-provider
                #default="{ errors }"
                name="Manager"
                rules="required"
              >
                <b-form-input
                  v-model="manager.username"
                  :state="errors.length > 0 ? false : null"
                  placeholder="Manager"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>

          <b-col md="6">
            <b-form-group
              label="Téléphone"
              label-for="Téléphone"
            >
              <validation-provider
                #default="{ errors }"
                name="Téléphone"
                rules="integer|length:8"
              >
                <b-form-input
                  v-model="manager.phone"
                  :state="errors.length > 0 ? false : null"
                  placeholder="Téléphone "
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col md="6">
            <b-form-group
              label="Email"
              label-for="Email"
            >
              <validation-provider
                #default="{ errors }"
                name="Email"
                rules="required"
              >
                <b-form-input
                  v-model="manager.email"
                  :state="errors.length > 0 ? false : null"
                  placeholder="Email"
                  type="email"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col md="6">
            <b-form-group
              label="Adresse"
              label-for="adress"
            >
              <validation-provider
                #default="{ errors }"
                name="adress"
              >
                <b-form-input
                  v-model="manager.address"
                  :state="errors.length > 0 ? false : null"
                  placeholder="Adresse"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col cols="6">
            <b-form-group
              label="Mot de passe"
              label-for="a-password"
            >
              <validation-provider
                #default="{ errors }"
                name="Password"
                vid="Password"
                rules="required|min:8"
              >
                <b-form-input
                  id="a-password"
                  v-model="password"
                  :state="errors.length > 0 ? false : null"
                  type="password"
                  placeholder="Password"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>

          <!-- confirm password -->
          <b-col cols="6">
            <b-form-group
              label="Confirmer mot de passe"
              label-for="password"
            >
              <validation-provider
                #default="{ errors }"
                name="Confirm Password"
                rules="required|confirmed:Password"
              >
                <b-form-input
                  id="ac-password"
                  v-model="manager.password"
                  :state="errors.length > 0 ? false : null"
                  type="password"
                  placeholder="Confirm Password"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>

          <b-col
            md="12"
          >
            <b-form-group
              label="Dépot"
              label-for="depot"
            >
              <validation-provider
                #default="{ errors }"
                name="depot"
                rules="required"
              >
                <v-select
                  v-model="manager.repository"
                  :clearable="false"
                  placeholder="Dépots"
                  label="name"
                  :options="repositories"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>

          <b-col cols="12">
            <b-button
              variant="primary"
              type="submit"
              class="mb-1 mb-sm-0 mr-0 mr-sm-1"
              :class="{ 'disabled-cursor': isLoading || !isManagerValid }"
              :disabled="isLoading || !isManagerValid"
              @click.prevent="addManager"
            >
              Ajouter
            </b-button>
          </b-col>
        </b-row>

      </b-form>
    </validation-observer>
  </b-card-code>
</template>

<script>
import axios from 'axios'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import BCardCode from '@core/components/b-card-code'
import vSelect from 'vue-select'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import {
  BFormInput,
  BFormGroup,
  BForm,
  BRow,
  BCol,
  BButton,
  BCardText,
} from 'bootstrap-vue'
import { required } from '@validations'

export default {
  components: {
    BCardCode,
    ValidationProvider,
    ValidationObserver,
    BCardText,
    BFormInput,
    BFormGroup,
    BForm,
    BRow,
    BCol,
    BButton,
    vSelect,
  },
  data() {
    return {
      username: '',
      password: '',
      repositories: [],
      manager: {
        username: '',
        email: '',
        password: '',
        address: '',
        phone: '',
      },
      required,
      isLoading: false,
    }
  },
  computed: {
    isManagerValid() {
      return (
        this.manager.email !== ''
        && this.manager.password !== ''
        && this.manager.username !== ''
        && this.manager.phone !== ''
        && this.manager.address !== ''
      )
    },
  },
  created() {
    this.getRepositories()
  },
  methods: {
    async getRepositories() {
      const { data } = await axios.get('/api/repositories/')
      this.repositories = data
    },
    async addManager() {
      this.$refs.simpleRules.validate().then(async success => {
        if (success) {
          try {
            this.isLoading = true
            await axios.post('/api/auth/register/manager/',
              {
                email: this.manager.email,
                password: this.manager.password,
                username: this.manager.username,
                phone: this.manager.phone,
                address: this.manager.address,
                repository: this.manager.repository.id,
              })
            this.isLoading = false
            setTimeout(() => {
              this.showToast('success', 'top-center', 'responsable ajouté avec succés')
            }, 1000)
            this.$router.push('/managers')
          } catch (error) {
            setTimeout(() => {
              this.showToast('danger', 'top-center', error.toString())
            }, 1000)
          }
        } else {
          setTimeout(() => {
            this.showToast('danger', 'top-center', 'champ obligatoire ! vérifier votre donnée !')
          }, 1000)
        }
      })
    },
    showToast(variant, position, text) {
      this.$toast(
        {
          component: ToastificationContent,
          props: {
            title: 'Notification',
            icon: 'InfoIcon',
            text,
            variant,
          },
        },
        {
          position,
        },
      )
    },
  },
}
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
</style>
