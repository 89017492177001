var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-card-code',{attrs:{"title":"Créer un nouveau responsable"}},[_c('b-card-text',[_c('span',[_vm._v("Veuillez saisir les coordonnées de responsable ")])]),_c('validation-observer',{ref:"simpleRules"},[_c('b-form',[_c('b-row',[_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',{attrs:{"label":"Manager","label-for":"Manager"}},[_c('validation-provider',{attrs:{"name":"Manager","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"state":errors.length > 0 ? false : null,"placeholder":"Manager"},model:{value:(_vm.manager.username),callback:function ($$v) {_vm.$set(_vm.manager, "username", $$v)},expression:"manager.username"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',{attrs:{"label":"Téléphone","label-for":"Téléphone"}},[_c('validation-provider',{attrs:{"name":"Téléphone","rules":"integer|length:8"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"state":errors.length > 0 ? false : null,"placeholder":"Téléphone "},model:{value:(_vm.manager.phone),callback:function ($$v) {_vm.$set(_vm.manager, "phone", $$v)},expression:"manager.phone"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',{attrs:{"label":"Email","label-for":"Email"}},[_c('validation-provider',{attrs:{"name":"Email","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"state":errors.length > 0 ? false : null,"placeholder":"Email","type":"email"},model:{value:(_vm.manager.email),callback:function ($$v) {_vm.$set(_vm.manager, "email", $$v)},expression:"manager.email"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',{attrs:{"label":"Adresse","label-for":"adress"}},[_c('validation-provider',{attrs:{"name":"adress"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"state":errors.length > 0 ? false : null,"placeholder":"Adresse"},model:{value:(_vm.manager.address),callback:function ($$v) {_vm.$set(_vm.manager, "address", $$v)},expression:"manager.address"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"cols":"6"}},[_c('b-form-group',{attrs:{"label":"Mot de passe","label-for":"a-password"}},[_c('validation-provider',{attrs:{"name":"Password","vid":"Password","rules":"required|min:8"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"a-password","state":errors.length > 0 ? false : null,"type":"password","placeholder":"Password"},model:{value:(_vm.password),callback:function ($$v) {_vm.password=$$v},expression:"password"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"cols":"6"}},[_c('b-form-group',{attrs:{"label":"Confirmer mot de passe","label-for":"password"}},[_c('validation-provider',{attrs:{"name":"Confirm Password","rules":"required|confirmed:Password"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"ac-password","state":errors.length > 0 ? false : null,"type":"password","placeholder":"Confirm Password"},model:{value:(_vm.manager.password),callback:function ($$v) {_vm.$set(_vm.manager, "password", $$v)},expression:"manager.password"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"12"}},[_c('b-form-group',{attrs:{"label":"Dépot","label-for":"depot"}},[_c('validation-provider',{attrs:{"name":"depot","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{attrs:{"clearable":false,"placeholder":"Dépots","label":"name","options":_vm.repositories},model:{value:(_vm.manager.repository),callback:function ($$v) {_vm.$set(_vm.manager, "repository", $$v)},expression:"manager.repository"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"cols":"12"}},[_c('b-button',{staticClass:"mb-1 mb-sm-0 mr-0 mr-sm-1",class:{ 'disabled-cursor': _vm.isLoading || !_vm.isManagerValid },attrs:{"variant":"primary","type":"submit","disabled":_vm.isLoading || !_vm.isManagerValid},on:{"click":function($event){$event.preventDefault();return _vm.addManager.apply(null, arguments)}}},[_vm._v(" Ajouter ")])],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }